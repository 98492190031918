const MHeaderLine = () => {
  return (
    <div className="relative w-full h-fit bg-gradient-to-t to-[var(--light-color)] from-[var(--main-color)] text-white">
      <div className="relative w-full h-fit px-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] mx-auto flex items-center py-2">
        <div className="relative w-fit min-w-[380px] h-fit hidden lg:block">
          <p>
            <span className="text-[#fff700]">
              서울&nbsp;·&nbsp;경기&nbsp;·&nbsp;인천&nbsp;전지역&nbsp;출장비&nbsp;없는&nbsp;출장견적&nbsp;
            </span>
            |&nbsp;
          </p>
        </div>
        <div className="relative w-fit h-fit flow-text">
          <ul className="flex gap-5 flow-wrap text-base">
            <li>김00 (고독사정리)</li>
            <li>이OO (유품정리)</li>
            <li>오OO (쓰레기집청소)</li>
            <li>신OO (특수청소)</li>
            <li>임OO (빈집정리)</li>
            <li>김OO (생전정리)</li>
            <li>이OO (고독사정리)</li>
            <li>오OO (쓰레기집청소)</li>
            <li>신OO (생전정리)</li>
            <li>임OO (특수청소)</li>
          </ul>
          <ul className="flex gap-5 flow-wrap text-base">
            <li>김00 (고독사정리)</li>
            <li>이OO (유품정리)</li>
            <li>오OO (쓰레기집청소)</li>
            <li>신OO (특수청소)</li>
            <li>임OO (빈집정리)</li>
            <li>김OO (생전정리)</li>
            <li>이OO (고독사정리)</li>
            <li>오OO (쓰레기집청소)</li>
            <li>신OO (생전정리)</li>
            <li>임OO (특수청소)</li>
          </ul>
        </div>
        <div className="relative w-fit min-w-36 lg:min-w-[195px] h-fit ">
          <p>
            &nbsp;|&nbsp;&nbsp;&nbsp;
            <span className="text-[#fff700] xl:text-xl">실시간&nbsp;견적현황</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MHeaderLine;
